function Pc() {
    return(
        <div style={{height:'75px',width:'75px',transform:'scale(1)',opacity:'0.7'}}>
            <svg viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2 6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H13V19H15C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21H9C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19H11V18H5C3.34315 18 2 16.6569 2 15V6ZM5 5C4.44772 5 4 5.44772 4 6V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V6C20 5.44772 19.5523 5 19 5H5Z"
                      fill="#ffffff"/>
            </svg>
        </div>
    )
}export default Pc