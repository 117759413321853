function MoreLangs({show = true}) {

    const itemStyle = (i)=> {
        return{
            position: "absolute",
            bottom: 0,
            left: "0px",
            height: "75px",
            width: "75px",
            opacity: `${show ? "0" : "1"}`,
            transform: `${show ? "translateY(0)" : "translateX("+(1000+i*50)+"px)"}`,
            transition: "transform "+(3.5-i*0.05)+"s linear " + (i*0.25) + `s , ${show?"":"opacity 0.25s linear " + (3.25+i*0.20) + "s"}`,
        }
    }
    return (
        <div style={{
            opacity : show?"1":"0",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            transition: show?"opacity 1.5s linear":""
        }}>
            <div style={itemStyle(0)}>
                <svg fill="#ffffff" viewBox="0 0 32 32">
                    <path
                        d="M20.288 14.275l-0.539 2.474h2.091l0.529-2.474zM20.11 10.095h1.045l-0.694 3.314h2.076l0.709-3.314h1.012l-0.694 3.314 2.164-0.005-0.187 0.871h-2.156l-0.515 2.474h2.314l-0.22 0.872h-2.269l-0.725 3.28h-1.037l0.709-3.28h-2.062l-0.694 3.28h-1.012l0.694-3.28h-2.174l0.164-0.872h2.184l0.501-2.469h-2.282l0.172-0.871h2.282zM12.783 9.902c0.894 0.006 1.746 0.179 2.527 0.491l-0.047-0.016v1.476c-0.787-0.445-1.729-0.708-2.731-0.708-0.028 0-0.056 0-0.085 0.001l0.004-0c-0.043-0.002-0.093-0.002-0.143-0.002-1.237 0-2.351 0.522-3.135 1.358l-0.002 0.002c-0.789 0.897-1.271 2.081-1.271 3.377 0 0.089 0.002 0.178 0.007 0.267l-0-0.012c-0.004 0.076-0.007 0.164-0.007 0.253 0 1.222 0.45 2.339 1.193 3.194l-0.005-0.006c0.736 0.789 1.782 1.282 2.943 1.282 0.056 0 0.111-0.001 0.167-0.003l-0.008 0c0.043 0.001 0.094 0.002 0.145 0.002 1.081 0 2.094-0.295 2.962-0.809l-0.027 0.015-0.005 1.35c-0.885 0.44-1.928 0.698-3.030 0.698-0.088 0-0.175-0.002-0.262-0.005l0.013 0c-0.069 0.003-0.149 0.005-0.23 0.005-1.495 0-2.844-0.623-3.802-1.624l-0.002-0.002c-0.944-1.054-1.521-2.454-1.521-3.988 0-0.096 0.002-0.191 0.007-0.285l-0 0.013c-0.004-0.081-0.006-0.175-0.006-0.271 0-1.669 0.652-3.186 1.714-4.31l-0.003 0.003c1.052-1.076 2.518-1.743 4.139-1.743 0.070 0 0.141 0.001 0.211 0.004l-0.010-0q0.075-0.003 0.15-0.005 0.076-0.001 0.152 0zM16 1.004c0 0 0 0-0 0-8.282 0-14.996 6.714-14.996 14.996s6.714 14.996 14.996 14.996c8.282 0 14.996-6.714 14.996-14.996 0-4.141-1.678-7.89-4.392-10.604v0c-2.714-2.714-6.463-4.392-10.604-4.392v0z"></path>
                </svg>
            </div>
            <div style={itemStyle(1)}>
                <svg fill="#ffffff" viewBox="0 0 32 32">
                    <path d="M30.996 30.996h-29.992v-29.992h29.992l-14.996 14.996 14.996 14.996z"></path>
                </svg>
            </div>
            <div style={itemStyle(2)}>
                <svg fill="#ffffff" viewBox="0 0 22 22">
                    <path id="rect12"
                          d="M10.986 1.693c-2.642 0-4.769 1.064-4.769 2.385v1.88l4.775.019-.02.485h-7.14c-1.32 0-2.383 2.127-2.383 4.77C1.449 13.872 2.512 16 3.833 16H5.74c0-1.202.074-2.112.25-2.815.176-.703.462-1.207.883-1.544.842-.674 2.086-.648 3.875-.648 2.026 0 3.295-.027 4-.55.353-.26.599-.648.765-1.284.166-.637.242-1.514.242-2.697h.477c0 1.203-.074 2.113-.258 2.817-.184.705-.49 1.213-.941 1.547-.905.67-2.259.644-4.285.644-1.789 0-2.929.025-3.577.543-.324.26-.56.65-.719 1.288-.16.638-.235 1.517-.235 2.699v2.384c0 1.321 2.127 2.385 4.77 2.385 2.641 0 4.768-1.064 4.768-2.385v-1.899L11 16.486V16h7.14c1.32 0 2.384-2.127 2.384-4.769 0-2.642-1.064-4.769-2.385-4.769h-2.384V4.078c0-1.321-2.127-2.384-4.769-2.384zm-2.63 1.503h.007a.882.882 0 1 1-.006 0zm5.247 14.306h.006a.882.882 0 1 1-.006 0z"/>
                </svg>
            </div>
            <div style={itemStyle(3)}>
                <svg fill="#ffffff" viewBox="0 0 32 32">
                    <path
                        d="M17.313 14.789h-2.809c0 2.422-0.011 4.829-0.011 7.254 0.033 0.329 0.051 0.712 0.051 1.099 0 0.81-0.081 1.601-0.236 2.365l0.013-0.076c-0.412 0.861-1.475 0.751-1.957 0.6-0.451-0.242-0.808-0.609-1.031-1.055l-0.006-0.014c-0.044-0.094-0.097-0.174-0.16-0.246l0.001 0.001-2.281 1.406c0.367 0.79 0.934 1.434 1.637 1.885l0.018 0.011c0.763 0.427 1.675 0.678 2.645 0.678 0.484 0 0.954-0.063 1.401-0.18l-0.038 0.009c0.988-0.248 1.793-0.89 2.254-1.744l0.009-0.019c0.359-0.914 0.566-1.973 0.566-3.080 0-0.388-0.026-0.77-0.075-1.145l0.005 0.044c0.015-2.567 0-5.135 0-7.722zM28.539 23.843c-0.219-1.368-1.11-2.518-3.753-3.59-0.92-0.431-1.942-0.731-2.246-1.425-0.063-0.158-0.099-0.341-0.099-0.532 0-0.124 0.015-0.244 0.044-0.359l-0.002 0.010c0.208-0.55 0.731-0.935 1.343-0.935 0.199 0 0.388 0.040 0.559 0.113l-0.009-0.004c0.552 0.19 0.988 0.594 1.215 1.112l0.005 0.013c1.292-0.845 1.292-0.845 2.193-1.406-0.216-0.369-0.459-0.689-0.734-0.977l0.002 0.002c-0.767-0.814-1.852-1.32-3.056-1.32-0.171 0-0.34 0.010-0.505 0.030l0.020-0.002-0.881 0.111c-0.856 0.194-1.587 0.639-2.133 1.252l-0.003 0.004c-0.535 0.665-0.859 1.519-0.859 2.449 0 1.279 0.613 2.415 1.56 3.131l0.010 0.007c1.706 1.275 4.2 1.555 4.519 2.755 0.3 1.462-1.087 1.931-2.457 1.762-0.957-0.218-1.741-0.83-2.184-1.652l-0.009-0.017-2.287 1.313c0.269 0.536 0.607 0.994 1.011 1.385l0.001 0.001c2.174 2.194 7.61 2.082 8.586-1.255 0.113-0.364 0.178-0.782 0.178-1.215 0-0.3-0.031-0.593-0.091-0.875l0.005 0.028zM1.004 1.004h29.991v29.991h-29.991z"></path>
                </svg>
            </div>
            <div style={itemStyle(4)}>
                <svg fill="#ffffff" viewBox="0 0 512 512">
                    <path display="inline" d="M108.669,0.501h23.032v22.756h21.069V0.501h23.034V69.41h-23.032V46.334h-21.069V69.41h-23.032V0.501
		H108.669z M206.091,23.353h-20.275V0.501h63.594v22.852h-20.285V69.41h-23.032V23.353H206.091z M259.502,0.501h24.02l14.771,24.213
		l14.759-24.213h24.023V69.41h-22.938V35.256l-15.845,24.5h-0.395l-15.856-24.5V69.41h-22.539V0.501z M348.54,0.501h23.038v46.133
		h32.391V69.41H348.54V0.501z M74.987,100.926l32.946,369.533l147.844,41.04L404.031,470.4l32.981-369.475H74.987z M368.289,188.62
		l-2.063,22.977l-0.906,10.188h-0.149H256h-0.158h-63.956l4.142,46.407h59.814H256h92.98h12.214l-1.106,12.172l-10.65,119.32
		l-0.682,7.652L256,433.045v0.008l-0.208,0.059l-92.839-25.774l-6.351-71.161h20.97h24.527l3.227,36.146l50.474,13.632l0.042-0.013
		v-0.004l50.551-13.64l5.257-58.781H256h-0.158H154.578L143.439,188.62l-1.085-12.157h113.488H256h113.374L368.289,188.62z">
                    </path>
                </svg>
            </div>
            <div style={itemStyle(5)}>
                <svg fill="#ffffff" viewBox="0 0 512 512">
                    <path display="inline" d="M204.064,46.682v22.018h-56.302V0.501h56.302v24.13h-32.172v22.051H204.064z M261.941,24.631h22.492
		V0.501h-56.308c0,0,0,12.112,0,24.13c7.533,7.533,11.461,11.139,22.356,22.034c-6.369,0-22.356,0.022-22.356,0.021v22.014h56.308
		V46.682L261.941,24.631z M341.746,24.631h22.49V0.501H307.93c0,0,0,12.112,0,24.13c7.531,7.533,11.461,11.139,22.355,22.034
		c-6.365,0-22.355,0.022-22.355,0.021v22.014h56.307V46.682L341.746,24.631z M438.395,101.112l-34.203,370.486l-148.193,39.9
		l-148.196-39.9L73.605,101.112H438.395z M369.477,176.444H255.865h-115.62l5.529,44.739h110.091h6.184l-6.184,2.574
		l-106.067,44.177l3.518,43.73l102.549,0.333l54.963,0.175l-3.521,58.311l-51.442,14.484v-0.046l-0.422,0.116l-49.684-12.542
		l-3.015-36.195h-0.164h-46.085h-0.162l6.032,69.876l93.5,27.649v-0.05l0.168,0.05l93-27.146L361.1,267.935H255.865h-0.26
		l0.26-0.112l109.086-46.639L369.477,176.444z">
                    </path>
                </svg>
            </div>
        </div>
    )
}

export default MoreLangs;