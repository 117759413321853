import "./PointedStar.css";
import React from "react";

function PointedStar() {
    return (
        <div>
            <div className="five-pointed-star"></div>
        </div>
    );
}

export default PointedStar;