function Parachute() {
    return (<div style={{height:'125px',width:'125px',transform:'scale(1)',opacity:'0.7'}}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512.001 512.001">
<path fill="#ffffff" d="M452.666,205.814c-26.977-27.587-71.366-27.587-98.331,0c-13.483-13.788-31.333-20.694-49.172-20.694
	c-17.838,0-35.677,6.906-49.159,20.694c-13.495-13.788-31.333-20.694-49.172-20.694c-17.838,0-35.677,6.906-49.159,20.694
	c-13.483-13.788-31.333-20.694-49.172-20.694s-35.677,6.906-49.159,20.694c0-108.617,88.045-196.662,196.662-196.662
	S452.666,97.198,452.666,205.814z"/>
            <g>
	<path fill="#ffffff" d="M256.004,205.814V9.152c0.427,0,0.854,0,1.269,0.012c53.723,1.367,97.062,88.887,97.062,196.65
		c-13.483-13.788-31.333-20.694-49.172-20.694C287.325,185.121,269.487,192.027,256.004,205.814z"/>
                <path fill="#ffffff" d="M59.342,205.814c0-108.19,87.362-195.979,195.393-196.65c-53.723,1.367-97.062,88.887-97.062,196.65
		c-13.483-13.788-31.333-20.694-49.172-20.694S72.825,192.027,59.342,205.814z"/>
</g>
            <path fill="#ffffff" d="M256.001,0C142.514,0,50.186,92.328,50.186,205.814c0,1.456,0.348,2.892,1.014,4.186l0.798,1.551
	c0.154,0.3,0.325,0.591,0.511,0.871l195.784,295.246c1.009,1.62,2.487,2.87,4.227,3.598c0.121,0.051,0.239,0.105,0.362,0.15
	c0.233,0.085,0.469,0.16,0.708,0.227c0.195,0.055,0.393,0.101,0.593,0.143c0.201,0.041,0.4,0.085,0.605,0.115
	c0.368,0.052,0.743,0.082,1.12,0.089c0.045,0,0.088,0.011,0.132,0.011c0.005,0,0.011-0.001,0.016-0.001
	c0.006,0,0.013,0.001,0.02,0.001c0.001,0,0.002,0,0.004,0c0.454,0,0.9-0.044,1.342-0.11c0.071-0.011,0.14-0.027,0.211-0.04
	c0.395-0.068,0.782-0.162,1.162-0.281c0.051-0.016,0.102-0.032,0.154-0.049c1.922-0.637,3.612-1.888,4.759-3.621l196.589-297.036
	c0.992-1.498,1.52-3.254,1.52-5.05C461.815,92.328,369.487,0,256.001,0z M442.503,186.417c-11.717-6.805-25.06-10.448-39.005-10.448
	c-14.516,0-28.387,3.935-40.442,11.289c-2.138-46.952-12.362-90.647-29.339-124.599c-7.366-14.733-15.721-27.036-24.809-36.743
	C380.635,47.045,434.612,109.99,442.503,186.417z M246.85,446.784l-79.761-237.309c10.939-9.825,24.899-15.204,39.746-15.204
	c14.966,0,29.037,5.464,40.014,15.442L246.85,446.784L246.85,446.784z M265.15,209.714c10.978-9.978,25.047-15.442,40.016-15.442
	c14.847,0,28.809,5.381,39.747,15.205L265.15,446.974V209.714z M305.166,175.969c-14.346,0-28.06,3.848-40.016,11.036V19.344
	c7.905,1.785,15.682,5.875,23.216,12.248c10.757,9.1,20.508,22.305,28.981,39.251c15.696,31.393,25.225,71.989,27.369,115.886
	C332.871,179.721,319.327,175.97,305.166,175.969z M223.634,31.592c7.533-6.373,15.31-10.463,23.214-12.248v167.661
	c-11.956-7.188-25.667-11.035-40.013-11.036c-0.001,0,0,0,0,0c-14.159,0-27.701,3.751-39.547,10.758
	c2.145-43.896,11.669-84.492,27.365-115.884C203.126,53.897,212.877,40.692,223.634,31.592z M148.855,210.01l-0.804,0.27
	l77.087,229.355L71.159,207.431c10.547-8.521,23.563-13.16,37.344-13.16C123.622,194.271,137.83,199.839,148.855,210.01z
	 M363.949,210.281l-0.804-0.27c11.025-10.172,25.234-15.74,40.352-15.74c13.718,0,26.678,4.598,37.201,13.045L286.903,439.69
	L363.949,210.281z M203.086,25.917c-9.085,9.706-17.437,22.011-24.802,36.742c-16.977,33.953-27.201,77.647-29.339,124.599
	c-12.055-7.354-25.924-11.289-40.442-11.289c-13.945,0-27.288,3.643-39.004,10.448C77.388,109.991,131.362,47.049,203.086,25.917z"
            />
</svg>
    </div>)
}

export default Parachute